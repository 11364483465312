// src/pages/Analysis.js
import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

function Analysis() {
  const data = {
    labels: ['Jan', 'Feb', 'Mrz', 'Apr', 'Mai', 'Jun'],
    datasets: [
      {
        label: 'Bitcoin Preis',
        data: [34000, 37000, 35000, 38000, 40000, 42000],
        borderColor: 'rgba(75,192,192,1)',
        backgroundColor: 'rgba(75,192,192,0.2)',
        fill: true,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        labels: {
          color: '#ffffff',
        },
      },
      title: {
        display: true,
        text: 'Bitcoin Preisentwicklung (2023)',
        color: '#ffffff',
      },
    },
    scales: {
      x: {
        ticks: {
          color: '#ffffff',
        },
      },
      y: {
        ticks: {
          color: '#ffffff',
        },
      },
    },
  };

  return (
    <div className="container mx-auto mt-10 text-white">
      <h2 className="text-3xl font-bold text-center">Analysen und Vorhersagen</h2>
      <div className="mt-8">
        <Line data={data} options={options} />
      </div>
    </div>
  );
}

export default Analysis;