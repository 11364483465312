// src/pages/Blog.js
import React from 'react';

function Blog() {
  const posts = [
    {
      id: 1,
      title: 'Die Zukunft von Bitcoin',
      excerpt: 'Ein Blick auf die möglichen Entwicklungen von Bitcoin in den nächsten Jahren...',
      date: '14. November 2023',
    },
    {
      id: 2,
      title: 'Ethereum 2.0 und seine Auswirkungen',
      excerpt: 'Was bedeutet das Upgrade für Entwickler und Investoren?',
      date: '10. November 2023',
    },
    // Weitere Posts
  ];

  return (
    <div className="container mx-auto mt-10 text-white">
      <h2 className="text-3xl font-bold text-center">Unser Blog</h2>
      <div className="mt-8 space-y-6">
        {posts.map((post) => (
          <div key={post.id} className="bg-gray-800 p-6 rounded-lg shadow">
            <h3 className="text-2xl font-semibold">{post.title}</h3>
            <p className="text-gray-400 text-sm">{post.date}</p>
            <p className="mt-4">{post.excerpt}</p>
            <button className="mt-4 text-blue-500 hover:underline">Weiterlesen</button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Blog;