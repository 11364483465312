// src/pages/Prices.js
import React from 'react';
import CryptoPrices from '../components/CryptoPrices';

function Prices() {
  return (
    <div className="container mx-auto mt-10 text-white">
      <h2 className="text-3xl font-bold text-center">Aktuelle Krypto-Kurse</h2>
      <CryptoPrices />
    </div>
  );
}

export default Prices;