// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Home from './pages/Home';
import Prices from './pages/Prices';
import Analysis from './pages/Analysis';
import Blog from './pages/Blog';
import PrivateRoute from './components/PrivateRoute';

function App() {
  const { isAuthenticated, loginWithRedirect, logout, isLoading, user } = useAuth0();

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-900 text-white">
        <p>Lade...</p>
      </div>
    );
  }

  return (
    <Router>
      <div className="min-h-screen flex flex-col bg-gray-900">
        <nav className="bg-gray-800 p-4 sticky top-0 z-50">
          <div className="container mx-auto flex justify-between items-center">
            <Link to="/" className="text-white text-2xl font-bold">
              Cryptsight
            </Link>
            <ul className="flex space-x-4">
              <li>
                <Link to="/prices" className="text-gray-300 hover:text-white">
                  Kurse
                </Link>
              </li>
              <li>
                <Link to="/analysis" className="text-gray-300 hover:text-white">
                  Analysen
                </Link>
              </li>
              <li>
                <Link to="/blog" className="text-gray-300 hover:text-white">
                  Blog
                </Link>
              </li>
            </ul>
            <div className="flex items-center space-x-4">
              {isAuthenticated ? (
                <>
                  <span className="text-gray-300">
                    Hallo, {user.name || user.email}
                  </span>
                  <button
                    onClick={() => logout({ returnTo: window.location.origin })}
                    className="text-gray-300 hover:text-white"
                  >
                    Ausloggen
                  </button>
                </>
              ) : (
                <button
                  onClick={() => loginWithRedirect()}
                  className="text-gray-300 hover:text-white"
                >
                  Einloggen
                </button>
              )}
            </div>
          </div>
        </nav>
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/prices" element={<Prices />} />
            <Route
              path="/analysis"
              element={
                <PrivateRoute>
                  <Analysis />
                </PrivateRoute>
              }
            />
            <Route path="/blog" element={<Blog />} />
          </Routes>
        </main>
        <footer className="bg-gray-800 p-4 text-center text-gray-400">
          © {new Date().getFullYear()} Cryptsight. Alle Rechte vorbehalten.
        </footer>
      </div>
    </Router>
  );
}

export default App;