// src/components/PrivateRoute.js
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

function PrivateRoute({ children }) {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  if (isLoading) {
    return <div className="text-white">Lade...</div>;
  }

  if (!isAuthenticated) {
    loginWithRedirect();
    return null;
  }

  return children;
}

export default PrivateRoute;