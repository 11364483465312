// src/pages/Home.js
import React from 'react';

function Home() {
  return (
    <div className="text-center mt-10 text-white">
      <h1 className="text-5xl font-bold">Willkommen bei Cryptsight</h1>
      <p className="mt-6 text-xl">
        Ihre Quelle für aktuelle Krypto-Kurse, Analysen und Vorhersagen.
      </p>
      <div className="mt-10 flex justify-center">
        <img
          src="/images/crypto-banner.jpg"
          alt="Kryptowährungen"
          className="w-full max-w-4xl rounded-lg shadow-lg"
        />
      </div>
    </div>
  );
}

export default Home;