// src/components/CryptoPrices.js
import React, { useEffect, useState } from 'react';

function CryptoPrices() {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch(
      'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc'
    )
      .then((response) => response.json())
      .then((data) => setData(data));
  }, []);

  return (
    <div className="container mx-auto mt-6">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {data.map((coin) => (
          <div key={coin.id} className="bg-gray-800 text-white p-4 rounded-lg shadow">
            <div className="flex items-center">
              <img src={coin.image} alt={coin.name} className="w-10 h-10 mr-2" />
              <h3 className="text-xl font-semibold">{coin.name}</h3>
            </div>
            <p className="mt-2">Preis: ${coin.current_price.toLocaleString()}</p>
            <p
              className={`mt-1 ${
                coin.price_change_percentage_24h >= 0 ? 'text-green-400' : 'text-red-400'
              }`}
            >
              24h Änderung: {coin.price_change_percentage_24h.toFixed(2)}%
            </p>
            <p className="mt-1">Marktkapitalisierung: ${coin.market_cap.toLocaleString()}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CryptoPrices;